export const environment = {
    production: false,
    apiUrl: 'https://api.dev.isifid.com',
    // apiUrl: 'https://api.dev.isifid.com',
    cdnUrl: 'https://cdn.dev.isifid.com',
    duration: 30000,
    domain: 'sponsorship.dev.isifid.com',
    sentryUrl: 'https://dc2f9a3c4b0a4b898eb69e57c7d32544@o342465.ingest.sentry.io/5692785',
    version: 'bbc26c5b',
    emailRecipientsBusiness: ['Sysadmin <sysadmin@isifid.com>', 'Hadham <hadham@isifid.com>'],
    isifidDefaultRecipient: 'sysadmin@isifid.com'
};
